import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useNavigate, useParams } from 'react-router-dom';
import './BhajanDetails.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesUp, faAnglesLeft, faPlus, faMinus, faShareNodes, faLanguage } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import './transliterator';

const BhajanDetails = ({ selectedBhajan, clearSelectedBhajan }) => {
  const navigate = useNavigate();
  const { index: paramIndex } = useParams();
  const [fontSize, setFontSize] = useState(1);
  const [bhajanDetails, setBhajanDetails] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [transliteratedText, setTransliteratedText] = useState('');
  const [transliteratedTitle, setTransliteratedTitle] = useState('');

  useEffect(() => {
    if (!selectedBhajan || selectedBhajan.index !== paramIndex) {
      fetch('/bhajans.json')
        .then(response => response.ok ? response.json() : Promise.reject('Failed to fetch bhajans.json'))
        .then(bhajansData => {
          const bhajan = bhajansData.find(bhajan => bhajan.index === paramIndex.toString());

          if (bhajan) {
            const title = bhajan.bhajanHin;
            fetch(`/Bhajans/${paramIndex}.md`)
              .then(response => response.ok ? response.text() : Promise.reject(`Failed to fetch bhajan ${paramIndex}.md`))
              .then(data => {
                setBhajanDetails({ index: paramIndex, bhajan: title, lyrics: data });
              })
              .catch(() => setBhajanDetails({ index: paramIndex, bhajan: title, lyrics: "This bhajan is not available." }));
          } else {
            setBhajanDetails({ index: paramIndex, bhajan: "Unknown Bhajan", lyrics: "This bhajan is not available." });
          }
        })
        .catch(error => {
          setBhajanDetails({ index: paramIndex, bhajan: "Unknown Bhajan", lyrics: "Error fetching data." });
        });
    } else {
      setBhajanDetails(selectedBhajan);
    }
  }, [paramIndex, selectedBhajan]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollButton = document.querySelector('.scroll-to-top');
      if (scrollButton) {
        if (window.scrollY > 200) {
          scrollButton.style.display = 'block';
        } else {
          scrollButton.style.display = 'none';
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (!bhajanDetails) {
    return null;
  }

  const { index, bhajan, lyrics } = bhajanDetails;

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const goBack = () => {
    navigate('/');
    clearSelectedBhajan();
  }

  const fontPlus = () => {
    setFontSize(prevSize => Math.min(prevSize + 0.1, 4));
  }

  const fontMinus = () => {
    setFontSize(prevSize => Math.max(prevSize - 0.1, 0.5));
  }

  const shareBhajan = () => {
    const url = `${window.location.origin}/bhajan/${index}`;
    const title = `#${index} ${bhajan}`;
    const text = `#${index} ${bhajan}\n`;

    if (navigator.share) {
      navigator.share({
        title: encodeURIComponent(title),
        text: text,
        url: url,
      });
    }
  };

  const handleScriptSelect = (script) => {
    setShowModal(false);
    transliterateBhajan(script);
  };

  const transliterateBhajan = (script) => {
    if (script && bhajanDetails?.lyrics) {
      const transliteratedBhajan = window.Transliterator.t(bhajanDetails.bhajan, 'devanagari', script.toLowerCase());
      const transliteratedLyrics = window.Transliterator.t(bhajanDetails.lyrics, 'devanagari', script.toLowerCase());
      setTransliteratedTitle(transliteratedBhajan);
      setTransliteratedText(transliteratedLyrics);
    };
  }

  return (
    <div className="bhajan-details-container">
      <div className="details-content" style={{ fontSize: `${fontSize}rem` }}>
        <h2 className="bhajan-title">
          <span className='index'> #{index} </span>
          {transliteratedTitle ? transliteratedTitle : bhajan}
        </h2>
        <ReactMarkdown className="lyrics-container">{transliteratedText ? transliteratedText : lyrics}</ReactMarkdown>
      </div>
      <div className="scroll-to-top" onClick={scrollToTop}>
        <FontAwesomeIcon icon={faAnglesUp} />
      </div>
      <div className="go-back" onClick={goBack}>
        <FontAwesomeIcon icon={faAnglesLeft} />
      </div>
      <div className="font-plus" onClick={fontPlus}>
        <FontAwesomeIcon icon={faPlus} />
      </div>
      <div className="font-minus" onClick={fontMinus}>
        <FontAwesomeIcon icon={faMinus} />
      </div>
      <div className='share-bhajan' onClick={shareBhajan}>
        <FontAwesomeIcon icon={faShareNodes} />
      </div>
      <div className='transliterate' onClick={() => setShowModal(true)}>
        <FontAwesomeIcon icon={faLanguage} />
      </div>

      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        contentLabel="Select Script"
        className="ReactModal__Content"
        overlayClassName="ReactModal__Overlay"
        appElement={document.getElementById('root')}
      >
        <h2>Select Script</h2>
        <button onClick={() => handleScriptSelect('Devanagari')}>देवनागरी</button>
        <button onClick={() => handleScriptSelect('Gujarati')}>ગુજરાતી</button>
        <button onClick={() => handleScriptSelect('Bengali')}>বাংলা</button>
        <button onClick={() => handleScriptSelect('Oriya')}>ଓଡ଼ିଆ</button>
        <button onClick={() => handleScriptSelect('Gurmukhi')}>ਗੁਰਮੁਖੀ</button>
        <button onClick={() => handleScriptSelect('Kannada')}>ಕನ್ನಡ</button>
        <button onClick={() => handleScriptSelect('Telugu')}>తెలుగు</button>
        <button onClick={() => handleScriptSelect('Tamil')}>தமிழ்</button>
        <button onClick={() => handleScriptSelect('Malayalam')}>മലയാളം</button>
        <button onClick={() => handleScriptSelect('IAST')}>IAST</button>
      </Modal>
    </div>
  );
};

export default BhajanDetails;