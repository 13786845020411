import React, { useState, useRef, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';
import BhajanDetails from './BhajanDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube, faFacebook, faInstagram, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';
import { faAnglesUp, faAnglesLeft, faPlus, faMinus, faShareNodes, faLanguage, faStar } from '@fortawesome/free-solid-svg-icons';
import { faHome, faSortAlphaDown, faSortAlphaUp, faSortNumericDown, faSortNumericUp, faCircleQuestion, faXmark, faLocationDot, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import NotFound from './NotFound';
import Modal from 'react-modal';

function App() {
  const [searchTerm, setSearchTerm] = useState('');
  const [bhajanList, setBhajanList] = useState([]);
  const [filteredBhajans, setFilteredBhajans] = useState([]);
  const [selectedBhajan, setSelectedBhajan] = useState(null);
  const [headerBackground, setHeaderBackground] = useState('var(--app-background-color)');
  const [sortType, setSortType] = useState('index');
  const [sortDirection, setSortDirection] = useState('asc');
  const [sortedBhajans, setSortedBhajans] = useState([]);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [favoriteList, setFavouriteList] = useState([]);

  const handleSortClick = (type) => {
    if (type === sortType) {

      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortType(type);
      setSortDirection('asc');
    }
  };

  useEffect(() => {
    const listToSearch = isFilterActive ? favoriteList : bhajanList;
    const filtered = listToSearch.filter(item =>
      item.bhajanEng.toLowerCase().includes(searchTerm.toLowerCase().trim()) ||
      item.bhajanHin.toLowerCase().includes(searchTerm.toLowerCase().trim()) ||
      item.bhajanIAST.toLowerCase().includes(searchTerm.toLowerCase().trim()) ||
      item.index.toString().includes(searchTerm.trim())
    );

    setFilteredBhajans(filtered);
  }, [searchTerm, bhajanList, favoriteList, isFilterActive]);

  useEffect(() => {
    fetch('/bhajans.json')
      .then(response => response.json())
      .then(data => {
        setBhajanList(data);
        setFilteredBhajans(data);
      })
      .catch(error => console.error('Error loading bhajans:', error));
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const newHeaderBackground = scrollY > 50 ? '#38474E' : 'var(--app-background-color)';
      setHeaderBackground(newHeaderBackground);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const sortedList = [...filteredBhajans].sort((a, b) => {
      if (sortType === 'index') {
        return sortDirection === 'asc' ? a.index - b.index : b.index - a.index;
      } else if (sortType === 'alphabetical') {
        const bhajanA = a.bhajanHin.toLowerCase();
        const bhajanB = b.bhajanHin.toLowerCase();
        return sortDirection === 'asc' ? bhajanA.localeCompare(bhajanB) : bhajanB.localeCompare(bhajanA);
      }
      return 0;
    });

    setSortedBhajans(sortedList);
  }, [filteredBhajans, sortType, sortDirection]);

  const handleBhajanClick = (index, bhajan) => {
    fetch(`/Bhajans/${index}.md`)
      .then(response => response.ok ? response.text() : Promise.reject(`Failed to fetch bhajan ${index}.md: ${response.status} ${response.statusText}`))
      .then(data => setSelectedBhajan({ index, bhajan, lyrics: data }))
      .catch(error => {
        console.error(error.message);
        setSelectedBhajan({ index, bhajan, lyrics: "This bhajan is not available." });
      });
  };

  const bhajansToRender = sortedBhajans.length > 0 ? sortedBhajans : filteredBhajans;

  const clearSelectedBhajan = () => {
    setSelectedBhajan(null);
  };

  const HomeButton = ({ clearSelectedBhajan }) => {
    const navigate = useNavigate();

    const handleHomeClick = () => {
      clearSelectedBhajan();
      setFavouriteList([]);
      setIsFilterActive(false);
      setSortType('index');
      setSortDirection('asc');
      navigate('/');
    };

    return (
      <button className="button" onClick={handleHomeClick}>
        <FontAwesomeIcon icon={faHome} />
      </button>
    );
  };

  const openHelpModal = () => {
    setIsHelpModalOpen(true);
  };

  const closeHelpModal = () => {
    setIsHelpModalOpen(false);
  };

  const openSocialMedia = (platform) => {
    const socialMediaLinks = {
      youtube: 'https://youtube.com/@SiddhaYogaAshram',
      facebook: 'https://facebook.com/siddhgufasawai',
      instagram: 'https://instagram.com/siddha.gufa',
      twitter: 'https://twitter.com/SiddhaGufa',
      maps: 'https://goo.gl/maps/ZgenrkWRv7yv1mpa6',
      gmail: 'https://mail.google.com/mail/u/0/?fs=1&to=siddhgufa@gmail.com&tf=cm'
    };

    const url = socialMediaLinks[platform];
    let appUrl = '';

    const isAndroid = /Android/i.test(navigator.userAgent);
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

    if (('ontouchstart' in window || navigator.msMaxTouchPoints) && !isIOS) {
      if (isAndroid) {
        switch (platform) {
          case 'youtube':
            appUrl = `intent:${url}#Intent;package=com.google.android.youtube;end`;
            break;
          case 'facebook':
            appUrl = `fb://page/${url.split('/').pop()}`;
            break;
          case 'instagram':
            appUrl = `instagram://user?username=${url.split('/').pop()}`;
            break;
          case 'twitter':
            appUrl = `twitter://user?screen_name=${url.split('/').pop()}`;
            break;
          case 'maps':
            appUrl = `https://goo.gl/maps/ZgenrkWRv7yv1mpa6`;
            break;
          case 'gmail':
            appUrl = `mailto:siddhgufa@gmail.com`;
            break;
          default:
            break;
        }
      }
      window.location.href = appUrl;
    }
    else {
      window.open(url, '_blank');
    }
  }

  const handleStar = (selectedList) => {
    if (selectedList === 'ShowAll') {
      setFavouriteList([]);
      setIsFilterActive(false);
    } else {
      const filtered = bhajanList.filter(bhajan => selectedList.includes(parseInt(bhajan.index)));
      setFavouriteList(filtered);
      setIsFilterActive(true);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <Router>
      <div className="App">
        <header className="App-header" style={{ backgroundColor: headerBackground }}>
          <h1>Shri Siddha Gufa Bhajan Library</h1>
          <div className="search-container">
            <input
              type="text"
              placeholder="Search Bhajans..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <HomeButton
              clearSelectedBhajan={clearSelectedBhajan}
              setIsFilterActive={setIsFilterActive}
              setSortType={setSortType}
              setSortDirection={setSortDirection}
            />
            <button className='button' onClick={openHelpModal}>
              <FontAwesomeIcon icon={faCircleQuestion} />
            </button>
          </div>
        </header>
        <div className="link-list">
          <Routes>
            <Route path="/" element={(
              <table>
                <thead>
                  <tr>
                    <th>Index</th>
                    <th>Bhajan Name
                      <button className="button-sort" onClick={() => handleSortClick('index')}>
                        <FontAwesomeIcon icon={sortType === 'index' ? (sortDirection === 'asc' ? faSortNumericDown : faSortNumericUp) : faSortNumericDown} />
                      </button>
                      <button className="button-sort" onClick={() => handleSortClick('alphabetical')}>
                        <FontAwesomeIcon icon={sortType === 'alphabetical' ? (sortDirection === 'asc' ? faSortAlphaDown : faSortAlphaUp) : faSortAlphaDown} />
                      </button>
                      <div className="dropdown" ref={dropdownRef}>
                        <button className="button-star" onClick={() => setDropdownOpen(!dropdownOpen)}>
                          <FontAwesomeIcon icon={isFilterActive ? faStar : faStarRegular} />
                        </button>
                        {dropdownOpen && (
                          <ul className="dropdown-menu">
                            <li onClick={() => { handleStar('ShowAll'); setDropdownOpen(false); }}><FontAwesomeIcon icon={faStarRegular} /> Show All</li>
                            <li onClick={() => { handleStar(Array.from({ length: 252 }, (_, i) => i + 1)); setDropdownOpen(false); }}><FontAwesomeIcon icon={faStar} /> List 1</li>
                          </ul>
                        )}
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {bhajansToRender.map(item => (
                    <tr key={item.index}>
                      <td>#{item.index}</td>
                      <td>
                        <Link
                          to={`/bhajan/${item.index}`}
                          className="link-item"
                          onClick={() => handleBhajanClick(item.index, item.bhajanHin)}
                        >
                          <h2>{item.bhajanHin}</h2>
                          <p>{item.bhajanEng}</p>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )} />
            <Route path="/bhajan/:index" element={<BhajanDetails selectedBhajan={selectedBhajan} clearSelectedBhajan={clearSelectedBhajan} />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </div>
      <Modal
        isOpen={isHelpModalOpen}
        onRequestClose={closeHelpModal}
        contentLabel="Help Modal"
        className="ReactModal__Content"
        overlayClassName="ReactModal__Overlay"
        appElement={document.getElementById('root')}
      >
        <div className="help-modal-header">
          <h2>About</h2>
          <button className="close-button" onClick={closeHelpModal}>
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>
        <div className="help-modal-content">
          <p>Jai Gurudev, and welcome to Shri Siddha Gufa Bhajan Library.</p>
          <p>This is an attempt to organize, collect and make available the various bhajans that are prevalent in our ashrams.
            We have collected these bhajans from various sources, such as the personal bhajan diaries of Gurubhais, and books published
            by the ashram, like Yoga Amrit Bhajanavali, Shri Yogeshwar Charitramala, Sadguru Yogic Bhajan Sangrah, Shri Sadguru Sankirtan Bhajanmala,
            Kamaleshwara Vandana, Guruvar Ke Prati etc.
          </p>
          <p>
            If you find any errors in the website, or in any of the bhajans, or if you have a suggestion for the website, or if you want to
            add a new bhajan to the list, please&nbsp;<a href="https://wa.me/+919958363051" target="_blank" rel="noopener noreferrer">WhatsApp</a>
            &nbsp;us the details.
          </p>
          <p>
            You may also write to us on&nbsp;<a href="https://siddhagufa.org/" target="_blank" rel="noopener noreferrer">siddhagufa.org</a>.
          </p>
          <h4>How to use this website:</h4>
          <section>
            <ul>
              <li>
                On the <FontAwesomeIcon icon={faHome} /> home page, we have the complete list of available bhajans.
                Click on any bhajan to view the lyrics.
              </li>
              <li>
                Bhajans can sorted numerically <FontAwesomeIcon icon={faSortNumericDown} /> or alphabetically <FontAwesomeIcon icon={faSortAlphaDown} />.
              </li>
              <li>
                Use <FontAwesomeIcon icon={faStarRegular} /> button to filter the bhajans based on defined categories.
              </li>
              <li>
                In the search container, bhajan titles can searched in English, or Hindi, or IAST, or by their serial number.
              </li>
              <li>
                On the bhajan lyrics page, <FontAwesomeIcon icon={faAnglesLeft} /> button can be used to go back to the
                home page and <FontAwesomeIcon icon={faAnglesUp} /> button to go to the top of the lyrics.
              </li>
              <li>
                <FontAwesomeIcon icon={faPlus} /> and <FontAwesomeIcon icon={faMinus} /> buttons can be used to adjust the font size of the lyrics
                for better readability.
              </li>
              <li>
                <FontAwesomeIcon icon={faShareNodes} /> button can be used to share that particular bhajan on WhatsApp etc.
              </li>
              <li>
                <FontAwesomeIcon icon={faLanguage} /> button can be used to change the script for the lyrics.
                Devanagari, Gujarati, Bengali, Odia, Gurmukhi, Kannada, Telugu, Tamil, Malayalam and IAST scripts are supported.
              </li>
            </ul>
          </section>
        </div>
        <div className="social-icons-container">
          <hr />
          <div className="social-icons">
            <a href="https://youtube.com/@SiddhaYogaAshram" target="_blank" rel="noopener noreferrer" onClick={(e) => { e.preventDefault(); openSocialMedia('youtube'); }}>
              <FontAwesomeIcon icon={faYoutube} />
            </a>
            <a href="https://facebook.com/siddhgufasawai" target="_blank" rel="noopener noreferrer" onClick={(e) => { e.preventDefault(); openSocialMedia('facebook'); }}>
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a href="https://instagram.com/siddha.gufa" target="_blank" rel="noopener noreferrer" onClick={(e) => { e.preventDefault(); openSocialMedia('instagram'); }}>
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a href="https://twitter.com/SiddhaGufa" target="_blank" rel="noopener noreferrer" onClick={(e) => { e.preventDefault(); openSocialMedia('twitter'); }}>
              <FontAwesomeIcon icon={faXTwitter} />
            </a>
            <a href="https://goo.gl/maps/ZgenrkWRv7yv1mpa6" target="_blank" rel="noopener noreferrer" onClick={(e) => { e.preventDefault(); openSocialMedia('maps'); }}>
              <FontAwesomeIcon icon={faLocationDot} />
            </a>
            <a href="https://mail.google.com/mail/u/0/?fs=1&to=siddhgufa@gmail.com&tf=cm" target="_blank" rel="noopener noreferrer" onClick={(e) => { e.preventDefault(); openSocialMedia('gmail'); }}>
              <FontAwesomeIcon icon={faEnvelope} />
            </a>
          </div>
        </div>
      </Modal>
    </Router>
  );
}

export default App;